<template>
    <standard-layout>
        <template #heading><h1>Die SPABoot-Gutscheine</h1></template>
        <template #main>
            <div class="row bg-white border border-secondary mx-1 rounded p-2 small">
                <div class="col-12 col-md-5 px-4 d-flex align-middle">
                    <div class="my-auto">
                        <cloudinary-image
                                class="img-fluid"
                                public-id="gutscheine"
                                width="640"
                                height="429"/>
                    </div>
                </div>
                <div class="col-12 col-md-7">
                    <h3 class="h3">Die Geschenkidee</h3>
                    <div>
                        Gutscheine für ein einzigartiges Erlebnis für bis zu 10 Personen.<br/>
                        Wir senden Dir sofort einen elektronischen Gutschein per Email. Zudem
                        erhältst Du einen beidseitig gedruckten, individuellen Gutschein per Post.<br/>
                        Der Gutschein enthält einen Code, den Du dann bei der Reservierung als Bezahlung nutzen
                        kannst.<br/>
                        Gutscheine sind immer bis zum Ende des übernächsten Kalenderjahrs gültig.
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3 p-3 border border-primary bg-light" v-if="inVacation">
                <strong>Hinweis</strong>: Wir haben in der Zeit vom <span style="color: red;">20.9.-28.9. Betriebsferien</span>. Gutscheine, die in
                diesem
                Zeitraum bestellt
                werden, können erst am <b style="color: red;">29.9.</b> per Post versendet werden. Die elektronische Form wird natürlich
                weiterhin
                sofort
                nach Bestellung
                per
                Email verschickt und kann auch sofort verwendet werden.
            </div>
            <div class="row mt-4">
                <div class="col-12 col-md-4 cursor-pointer mb-3"
                     v-for="article in articles"
                     :key="article.id">
                    <div class="voucher-box rounded border border-secondary h-100"
                         :class="{'bg-white': !selectedArticle || (selectedArticle.id !== article.id),
            'bg-success text-light': selectedArticle && (selectedArticle.id === article.id)}">
                        <cld-image :public-id="article.product.imageId"
                                   height="400"
                                   class="img-fluid"
                                   :alt="article.product.imageId"
                                   responsive="true">
                            <cld-transformation crop="scale"/>
                        </cld-image>
                        <div class="lower-box text-center">
                            <div class="product-info">
                                <div class="product-name col-12 font-weight-bolder mt-2">
                                    {{ article.product.name }}
                                </div>
                                <div class="product-description">{{ article.product.description }}</div>
                                <div class="product-detail-link mt-1" v-if="article.product.detailId">
                                    <div class="link small"
                                         @click="displayDetail({id: article.product.detailId, title: article.product.name})">
                                        <font-awesome-icon
                                                icon="info-circle"/>
                                        Details
                                    </div>
                                </div>
                            </div>
                            <div class="product-conditions-box w-100">
                                <div class="small text-nowrap" v-if="article.product.attendees > 0">
                                    {{ article.product.duration }}&nbsp;Stunden |
                                    {{ article.product.attendees }}&nbsp;Personen
                                </div>
                                <div class="product-price font-weight-bolder" v-if="article.product.attendees > 0">
                                    {{ article.grossPrice | price }}
                                </div>
                                <div>
                                    <div v-if="selectedArticle && selectedArticle.id === article.id">
                                        <font-awesome-icon icon="check-circle" size="1x"/>&nbsp;gewählt
                                    </div>
                                    <div class="btn btn-primary btn-sm" @click="selectArticle(article)"
                                         v-else>
                                        Wählen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="voucherDetailPanel" class="mt-4 card">
                <div class="card-header" v-if="selectedArticle">
                    {{ selectedArticle.product.name }}
                </div>
                <div class="card-body"
                     v-if="selectedArticleIsProductVoucher">
                    <div class="row"
                         id="attendeeSelector" v-if="attendeeArticle">
                        <div class="col-12 h5">Für wie viele Personen soll der Gutschein insgesamt gelten?</div>
                        <div class="col-12">
                            <div class="form-group">
                                <select class="form-control"
                                        v-model="addAttendees">
                                    <option v-for="a in [4,5,6,7,8,9,10]"
                                            :key="'att_' + a"
                                            :value="a - selectedArticle.product.attendees">
                                        {{ a }} Personen
                                        <span v-if="a > selectedArticle.product.attendees"
                                              class="text-muted">
                  (+{{ ((a - selectedArticle.product.attendees) * pricePerExtraAttendee) | price }})
                </span>
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{ selectedArticle.product.attendees }} Teilnehmer (kann nicht geändert werden)
                    </div>
                </div>
                <div class="card-body"
                     v-if="selectedArticleIsValueVoucher">
                    <div class="row">
                        <div class="col-12 h5">Wert des Gutscheins</div>
                        <div class="col-12">
                            <div class="input-group">
                                <input type="number"
                                       min="20"
                                       class="form-control"
                                       required
                                       v-model.number="voucherValue">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="inputGroupPrepend">€</span>
                                </div>
                                <div id="passwordHelpBlock" class="form-text text-muted">
                                    Mindestwert eines Gutschein ist 20€. Wert-Gutscheine können nur auf genau einen
                                    Einkauf angewendet
                                    werden.
                                    <span v-if="false">Es sind aber mehrere Wertgutscheine pro Einkauf möglich.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="voucherDetailPanelBottom"></div>
            </div>
            <div class="row mt-3 border-top border-secondary" v-if="cart && selectedArticle">
                <div class="col-12 mt-2 text-right font-weight-bold">
                    Gesamt: {{ totalPrice| price(2) }}
                </div>
                <div class="col-12 mt-2 text-right" v-if="selectedArticle">
                    <button class="btn btn-primary" @click="checkout">
                        Jetzt
                        Bestellen
                    </button>
                </div>
            </div>
            <div style="min-height: 20vh;"></div>
            <div v-if="displayedDetail">
                <modal :title="displayedDetail.title"
                       @close="displayedDetail = null">
                    <template v-slot:body>
                        <div v-html="displayedDetail.content"/>
                    </template>
                    <template v-slot:footer>
                        <button type="button" class="btn btn-secondary" @click="displayedDetail = null">OK</button>
                    </template>
                </modal>
            </div>
        </template>
    </standard-layout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import {mapActions, mapGetters} from "vuex";
import CloudinaryImage from "@/components/generic/CloudinaryImage";
import Modal from "@/components/generic/Modal";

export default {
    data() {
        return {
            articles: [],
            addAttendees: 0,
            voucherValue: 20,
            selectedArticle: undefined,
            attendeeArticle: undefined,
            displayedDetail: null
        }
    },
    computed: {
        ...mapGetters(['cart', 'cartImmutable']),
        inVacation() {
            return Date.now() < Date.UTC(2023, 9, 20)
        },
        selectedArticleIsProductVoucher() {
            return this.selectedArticle && !this.selectedArticle.product.valueVoucher
        },
        selectedArticleIsValueVoucher() {
            return this.selectedArticle && this.selectedArticle.product.valueVoucher
        },
        totalAttendees() {
            if (!this.selectedArticle)
                return 0
            return this.selectedArticle.product.attendees + this.addAttendees
        },
        pricePerExtraAttendee() {
            return this.attendeeArticle?.grossPrice || 0
        },
        pricePerAttendee() {
            if (this.totalAttendees > 0)
                return this.cart?.totalGrossPrice / this.totalAttendees
            return 0
        },
        totalPrice() {
            if (!this.selectedArticle)
                return 0
            if (this.selectedArticleIsValueVoucher)
                return this.voucherValue
            let total = this.selectedArticle.grossPrice
            if (this.addAttendees > 0)
                total += this.addAttendees * this.pricePerExtraAttendee
            return total
        }
    },
    watch: {
        totalAttendees(newVal) {
            if (this.selectedArticle) {
                const a = {...this.selectedArticle}
                a.attendees = newVal
                this.addArticleToCart({
                    article: a,
                    quantity: 1,
                    attendees: newVal
                }).catch(error => this.handleError(error))
            }
        },
    },
    methods: {
        ...mapActions(['updateCart', 'resetCart', 'addArticleToCart']),
        selectVoucherForProduct(productId) {
            let a = this.articles.find(a => a.product.voucherProductForId === productId)
            if (a)
                this.selectArticle(a)
        },
        async selectArticle(article) {
            if (!article) {
                this.selectedArticle = null;
                this.attendeeArticle = null;
                this.addAttendees = 0;
                return;
            }
            if (article.product.productVoucher) {
                this.voucherValue = 0
            } else {
                this.voucherValue = 20
            }
            this.selectedArticle = article
            if (!this.selectedArticleIsProductVoucher) {
                this.attendeeArticle = null
                this.addAttendees = 0
            } else {
                this.attendeeArticle = await this.$api.get('/articles/attendee', {
                    params: {
                        product: this.selectedArticle?.product?.voucherProductForId,
                        channel: 'WEB'
                    }
                }).then(response => response.data)
                    .catch(error => {
                        this.handleError(error)
                        return Promise.reject(error)
                    })
            }
            await this.$nextTick(() => this.$scrollTo('#voucherDetailPanelBottom'))
        },
        async checkout() {
            await this.resetCart()
            this.addArticleToCart({
                article: this.selectedArticle,
                quantity: 1,
                attendees: this.totalAttendees,
                priceAdd: this.voucherValue
            }).then(() => {
                this.$router.push({name: 'confirmBooking', params: {cart: this.cart.id}})
            }).catch(error => this.handleError(error))
        },
        displayDetail({id, title}) {
            this.$api.get(`/product-details/${id}`)
                .then(res => this.displayedDetail = {
                    ...res.data,
                    title: title
                }).catch(err => this.handleError(err))
        }
    },
    activated() {
        if (this.$route.query?.product) {
            this.selectVoucherForProduct(this.$route.query?.product)
        } else {
            this.selectArticle(null)
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$gtag.event('voucher_interest', {})
        })
    },
    mounted() {
        this.resetCart()
        this.$api.get('/articles', {
            params: {
                channel: process.env.VUE_APP_SPABOOT_CHANNEL_ID,
                category: 'VOU'
            }
        }).then(response => {
            this.articles = response.data
            if (this.$route.query?.product) {
                this.selectVoucherForProduct(this.$route.query?.product)
            }
        }).catch(error => this.handleError(error))
    },
    components: {StandardLayout, CloudinaryImage, Modal}
}
</script>

<style lang="scss">
.voucher-box {
  position: relative;
}

.lower-box {
  padding-bottom: 15vh;
  width: 100%;
}

.product-info {
}

.product-name {
}

.product-description {
  font-size: 0.9rem;
}

.product-conditions-box {
  display: block;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  align-content: center;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
